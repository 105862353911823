import React, {useState, useEffect} from 'react'
import axiosInstance from './../../http/axios';

import Toast from '../../toast';
import Header from './../header/Header';

import "../../../node_modules/bootstrap/dist/css/bootstrap.min.css"

import ArrowBackRounded from '@mui/icons-material/ArrowBackRounded';
import { IconButton } from '@mui/material';
import DropdownMenu from '../dropdown_menu/DropdownMenu';
import {Pagination} from '@mui/material';

import SearchOutlined from '@mui/icons-material/SearchOutlined';

import NavLink from '../nav_link/NavLink';

const Agent = () => {

     const naviagte = (a)=> {
          if(typeof a == 'string'){
               window.location.href = a;
          }
          else if(typeof a == 'number'){
               window.history.go(a);
          }
     };

     const [data, setData] = useState([]);
     const [maxLength, setMaxDataLength] = useState(0);
     const [errorMessage, setErrorMessage] = useState(null);
     const [userAccountStatus, setUserAccountStatus] = useState("agent");
     const [searchQuery, setSearchQuery] = useState(null);
     const tableHead = [ "ID", "Name", "Success Recharge Count", "Available Coin's", "User ID", ""];

     useEffect(()=>{
          
     }, [searchQuery]);

     const onPageChange = async (e, pageIndex, status) => {
          try {
               const params = new URLSearchParams();
               params.append("skip", 20 * (pageIndex-1));
               params.append("limit", 20);

               if(searchQuery){
                    params.append("searchQuery", searchQuery);
               }
               const res = await axiosInstance.get('admin/agent', {
                    params: params
               });
               setErrorMessage(null);
               setData(res.data.data);
               setMaxDataLength(res.data.maxLength);
          } 
          catch (error) {
               setErrorMessage("Failed");
          }
     }

     useEffect(async ()=>{
          onPageChange(null, 1);
     }, []);

     return (
          <>
               <div className="d-flex justify-content-between">
                    <div style={{height: '80px'}} className='d-flex align-items-center'>
                         {/* <IconButton onClick={()=>naviagte(-1)}><ArrowBackRounded/></IconButton> */}
                         <h5  style={{marginLeft: '10px', height: '100%', marginTop: '5px'}} className='d-flex align-items-center'>Agents</h5>
                    </div>
                    <div className='d-flex align-items-center'>
                         <div className='d-flex align-items-center' style={{border: '1px solid #A29694', marginRight: "20px", padding: '7px 20px', borderRadius: "5px"}}>
                              <input type="text" style={{background: "#F2EDF3", border: 'none', marginRight: "20px", }} placeholder='Search by user id...' onChange={(e)=>setSearchQuery(e.target.value.trim())}/>
                              <SearchOutlined htmlColor='#A29694' onClick={()=>{
                                   onPageChange(null, 1, userAccountStatus);
                              }} />
                         </div>
                    </div>
               </div>
               <div className="__table_root">
                    <table cellspacing="0" cellpadding="0" className="table">
                    <tr>
                         { tableHead.map((value, i)=> <th>{value}</th>) }
                    </tr>
                    { errorMessage ? null : ( data.length != 0 ? data.map((value, i)=> <Item data={value} position={i}/>) : null) }
                    </table>
                    { data.length != 0 ? null : <ErrorMessage message='Empty'/> }
                    { errorMessage&&data.length != 0 ? <ErrorMessage message={errorMessage}/> : null }
                    <div className='d-flex justify-content-between'>
                    <div></div>
                         <Pagination onChange={onPageChange} count={maxLength != 0 ? ~~((maxLength/20)+1) : 0} shape="rounded" />
                    </div>
               </div>
          </>
     );
}

const Item = (props) => {


     const [data, setData] = useState({...props.data});

     const { _id, agentUserName, userId, agentUserId, successRechargeCount, availableCoins, mUserId } = data;

     useEffect(()=> {
          setData(props.data);
     }, [props.data]);

     const naviagte = (a)=> {
          if(typeof a == 'string'){
               window.location.href = a;
          }
          else if(typeof a == 'number'){
               window.history.go(a);
          }
     };

     const getStatusView = (status) => {
          // rejected, submited, enabled, desabled, no_action
          var text = "";
          var className = "";
          if(status == "no_action"){
               text = "No action";
               className = "status_c_yellow";
          }
          else if(status == "submited"){
               text = "Submited";
               className = "status_c_blue";
          }
          else if(status == "desabled"){
               text = "Desabled";
               className = "status_c_red";
          }
          else if(status == "rejected"){
               text = "Rejected";
               className = "status_c_red";
          }
          else if(status == "enabled"){
               text = "Enabled";
               className = "status_c_green";
          }
          else {
               className = "status_c_yellow";
               text = "None";
          }

          return <span className={className + " item_status_view"}>{text}</span>
     }

     const onRecharge = async () => {
          try{
               const input = prompt("Add Diamonds", 100);
               if(!input || isNaN(input)) return;

               try {
                    const rBody = new FormData();
                    rBody.append("userId", agentUserId);
                    rBody.append("balance", input);
                    const res = await axiosInstance.patch("admin/user-add-coin", rBody, 
                         {
                              headers: { "Content-Type": "multipart/form-data" }
                         }
                    );
     
                    setData({
                         ...data,
                         availableCoins: availableCoins + Number(input),
                    });
               } 
               catch (error) {
                    Toast.error("Failed to update");
               }
          }
          catch(error){
               
          }
     }

     return(
          <tr>
               <td>{userId}</td>
               <td>{agentUserName}</td>
               <td>{successRechargeCount}</td>
               <td><span className='item_status_view status_c_yellow'>{availableCoins}</span></td>
               <td>{mUserId}</td>
               <td><button onClick={onRecharge} style={{background: "green", border: "none", color: "white", padding: "5px 15px", borderRadius: "5px"}} >Recharge</button></td>
          </tr>
     );
};

const ErrorMessage = (props) => {
     return (
          <div className='d-flex align-items-center justify-content-center' style={{height: "300px", fontSize: '16px'}}>
               {props.message}
          </div>
     );
}

export default Agent;