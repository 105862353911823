import React, {useState, useEffect} from 'react'
import axiosInstance from './../../http/axios';

import Toast from '../../toast';
import Header from './../header/Header';

import "../../../node_modules/bootstrap/dist/css/bootstrap.min.css"

import ArrowBackRounded from '@mui/icons-material/ArrowBackRounded';
import { IconButton } from '@mui/material';
import DropdownMenu from '../dropdown_menu/DropdownMenu';
import {Pagination} from '@mui/material';

import SearchOutlined from '@mui/icons-material/SearchOutlined';


import NavLink from '../nav_link/NavLink';

const Rooms = () => {

     const naviagte = (a)=> {
          if(typeof a == 'string'){
               window.location.href = a;
          }
          else if(typeof a == 'number'){
               window.history.go(a);
          }
     };

     const [data, setData] = useState([]);
     const [maxLength, setMaxDataLength] = useState(0);
     const [errorMessage, setErrorMessage] = useState(null);
     const [searchQuery, setSearchQuery] = useState(null);  
     const tableHead = ["Room ID", "Name", "Description", "Owner ID", "Gifting (This week)", "Gifting (Lifetime)", "Level", "Mic", "Membership Fee", "Entry Password", "Created At"];

     const onPageChange = async (e, pageIndex, status) => {
          try {
               const params = new URLSearchParams();
               params.append("skip", 20 * (pageIndex-1));
               params.append("limit", 20);
               params.append("filterByNew", true);

               if(searchQuery){
                    params.append("searchQuery", searchQuery);
               }
               const res = await axiosInstance.get('admin/rooms', {
                    params: params
               });
               setErrorMessage(null);
               setData(res.data.data);
               setMaxDataLength(res.data.maxLength);
          } 
          catch (error) {
               setErrorMessage("Failed");
          }
     }

     useEffect(async ()=>{
          onPageChange(null, 1);
     }, []);

     return (
          <>
               <div className="d-flex justify-content-between">
                    <div style={{height: '80px'}} className='d-flex align-items-center'>
                         {/* <IconButton onClick={()=>naviagte(-1)}><ArrowBackRounded/></IconButton> */}
                         <h5  style={{marginLeft: '10px', height: '100%', marginTop: '5px'}} className='d-flex align-items-center'>Room's</h5>
                    </div>
                    <div className='d-flex align-items-center'>
                         <div className='d-flex align-items-center' style={{border: '1px solid #A29694', marginRight: "20px", padding: '7px 20px', borderRadius: "5px"}}>
                              <input type="text" style={{background: "#F2EDF3", border: 'none', marginRight: "20px", }} placeholder='Search by room id...' onChange={(e)=>setSearchQuery(e.target.value.trim())}/>
                              <SearchOutlined htmlColor='#A29694' onClick={()=>{
                                   onPageChange(null, 1);
                              }} />
                         </div>
                         {/* <DropdownMenu items={["Active users", "Blocked users"]} onChange={(value, i)=> {
                                   const status = i == 0 ? "active" : "blocked";
                                   setUserAccountStatus(status);
                                   onPageChange(null, 1, status);
                              }}/> */}
                    </div>
               </div>
               <div className="__table_root">
                    <table cellspacing="0" cellpadding="0" className="table">
                    <tr>
                         { tableHead.map((value, i)=> <th>{value}</th>) }
                    </tr>
                    { errorMessage ? null : ( data.length != 0 ? data.map((value, i)=> <Item data={value} position={i}/>) : null) }
                    </table>
                    { data.length != 0 ? null : <ErrorMessage message='Empty'/> }
                    { errorMessage&&data.length != 0 ? <ErrorMessage message={errorMessage}/> : null }
                    <div className='d-flex justify-content-between'>
                    <div></div>
                         <Pagination onChange={onPageChange} count={maxLength != 0 ? ~~((maxLength/20)+1) : 0} shape="rounded" />
                    </div>
               </div>
          </>
     );
}

const Item = (props) => {
     

     const { _id, roomId, ownerId, roomName, roomDescription, coin, totalCoin, level, numberOfMic, membershipFee, entryPassword, date } = props.data;

     const getStatusView = (status) => {
          // rejected, submited, enabled, desabled, no_action
          var text = "";
          var className = "";
          if(status == "no_action"){
               text = "No action";
               className = "status_c_yellow";
          }
          else if(status == "submited"){
               text = "Submited";
               className = "status_c_blue";
          }
          else if(status == "desabled"){
               text = "Desabled";
               className = "status_c_red";
          }
          else if(status == "rejected"){
               text = "Rejected";
               className = "status_c_red";
          }
          else if(status == "enabled"){
               text = "Enabled";
               className = "status_c_green";
          }
          else {
               className = "status_c_yellow";
               text = "None";
          }

          return <span className={className + " item_status_view"}>{text}</span>
     }

     return( 
          <tr>
               <td><a href={'/admin-room?id=' + _id }>{roomId}</a></td>
               <td>{`${roomName}`.length > 20 ? `${roomName}`.substring(0, 19) + "..." : `${roomName}`}</td>
               <td>{`${roomDescription}`.length > 20 ? `${roomDescription}`.substring(0, 19) + "..." : `${roomDescription}`}</td>
               <td>{ownerId}</td>
               <td><span className='item_status_view status_c_yellow'>{coin.toLocaleString()}</span></td>
               <td><span className='item_status_view status_c_yellow'>{totalCoin.toLocaleString()}</span></td>
               <td>{level}</td>
               <td>{numberOfMic}</td>
               <td>{membershipFee ? membershipFee : "- -"}</td>
               <td>{entryPassword ? entryPassword : "- -"}</td>
               {/* <td>{level}</td> */}
               {/* <td>{level}</td> */}
               {/* <td><span>{gender.charAt(0).toUpperCase() + gender.slice(1)}</span></td> */}
               <td>{new Date(date).toDateString()}</td>
               {/* <td><span className={"item_status_view " + (vipLevel == 0 ? "status_c_red" : "status_c_green")}>{vipLevel == 0 ? "None" : `VIP ${vipLevel}`}</span></td> */}
               {/* <td><span className={"item_status_view " + (accountStatus == "active" ? "status_c_green" : "status_c_red")}>{accountStatus ? "Active" : "Blocked"}</span></td> */}
               {/* <td><button onClick={()=>{}} style={{background: "red", border: "none", color: "white", padding: "5px 15px", borderRadius: "5px"}} >Delete</button></td> */}
          </tr>
     );
};

const ErrorMessage = (props) => {
     return (
          <div className='d-flex align-items-center justify-content-center' style={{height: "300px", fontSize: '16px'}}>
               {props.message}
          </div>
     );
}

export default Rooms;