import React, { useState, useEffect } from "react";
import axiosInstance  from "../../http/axios";

import Header from './../header/Header';
import { IconButton,  } from '@mui/material';
import ArrowForwardRoundedIcon from '@mui/icons-material/ArrowForwardRounded';
import Toast from '../../toast';

import './Deshboard.css'

const Deshboard = () => {

     const [data, setData] = useState({
          users: 0,
          gifts: 0, 
          hostForms: 0,
          agents: 0,
          helpAndSupport: 0,
          withdrawalRequests: 0,
          banner: 0,
          rooms: 0,
          roomBackgrounds: 0,
     });

     useEffect(async ()=>{
          try {
               const res = await axiosInstance.get('admin/home-data');
               setData(res.data);
          }
          catch (error) {
               console.log(error);
               Toast.error('Failed');
          }
     }, []);

     return(
          <div>
               <div className="container">
                    <div className="row mt-4">
                         <Item className='col-3 mt-2' label="Users" value={data.users} redirect="/admin-users" />
                         <Item className='col-3 mt-2' label="Room's" value={data.rooms} redirect="/admin-rooms" />
                         <Item className='col-3 mt-2' label="Gift's" value={data.gifts} redirect="/admin-gifts" />
                         <Item className='col-3 mt-2' label="Agent's" value={data.agents} redirect="/admin-agent" />
                         <Item className='col-3 mt-2' label="Help & Support" value={data.helpAndSupport} redirect="/admin-help-and-support" />
                         <Item className='col-3 mt-2' label="Store" value={data.store} redirect="/admin-store" />
                         <Item className='col-3 mt-2' label="Medal's" value={data.medals} redirect="/admin-medals" />
                         <Item className='col-3 mt-2' label="Banner" value={data.banner} redirect="/admin-banner" />
                         <Item className='col-3 mt-2' label="Room Background's" value={data.roomBackgrounds} redirect="/admin-room-backgrounds" />
                         <Item className='col-3 mt-2' label="Notification" value="" redirect="/admin-notification" />
                         <Item className='col-3 mt-2' label="Setting" value="" redirect="/admin-setting" />
                    </div>
               </div>
          </div>
     );
}

const Item = (props) => {
     const navigate = (a)=> {
          if(typeof a == 'string'){
               window.location.href = a;
          }
          else if(typeof a == 'number'){
               window.history.go(a);
          }
     };
     return (
          <div className={"Deshboard_Item " + props.className} onClick={()=>navigate(props.redirect)}>
               <div className="Deshboard_Item_inner d-flex justify-content-between flex-column text-white">
                    <p style={{ fontSize: '20px', margin: '' }}>{props.label}</p>
                    <div className="d-flex justify-content-between align-items-baseline">
                         <p style={{fontWeight: 'bold', fontSize: '30px'}}>{props.value}</p>
                         <IconButton aria-label="delete" size="large">
                              <ArrowForwardRoundedIcon style={{ color: "#fff" }} fontSize="inherit"  />
                         </IconButton>
                    </div>
               </div>
          </div>
     );
}

export default Deshboard;