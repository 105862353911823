import React, {useState, useEffect} from 'react'
import NavLink from '../nav_link/NavLink';
import axiosInstance from './../../http/axios';

import Toast from '../../toast';
import Header from './../header/Header';

import "../../../node_modules/bootstrap/dist/css/bootstrap.min.css"

import ArrowBackRounded from '@mui/icons-material/ArrowBackRounded';
import AddRounded from '@mui/icons-material/AddRounded';
import { IconButton } from '@mui/material';
import Switch from '../switch/Switch';
import DropdownMenu from '../dropdown_menu/DropdownMenu';
import {Pagination} from '@mui/material';

const RoomBackground = () => {

     const naviagte = (a)=> {
          if(typeof a == 'string'){
               window.location.href = a;
          }
          else if(typeof a == 'number'){
               window.history.go(a);
          }
     };

     const [data, setData] = useState([]);
     const [maxLength, setMaxDataLength] = useState(0);
     const [status, setStatus] = useState(true);
     const [errorMessage, setErrorMessage] = useState(null);
     const tableHead = ["Background", "Enabled", "Date"];
     const selectionItems =  ["Enabled", "Desabled"];
     const selectionItemKeys = [true, false];

     const onPageChange = async (e, pageIndex, statusExist, mStatus) => {
          try {
               const params = new URLSearchParams();
               params.append("skip", 20 * (pageIndex-1));
               params.append("limit", 20);
               if(statusExist){
                    params.append("status", mStatus);
               }
               else params.append("status", status);

               const res = await axiosInstance.get('admin/room-background-list', {
                    params: params
               });
               setErrorMessage(null);
               setData(res.data.data);
               setMaxDataLength(res.data.maxLength);
          } 
          catch (error) {
               setErrorMessage("Failed");
               console.log(error);
          }
     }

     useEffect(async ()=>{
          onPageChange(null, 1);
     }, []);

     return (
          <>
               <Header/>
               <div className="d-flex justify-content-between">
                    <div style={{height: '80px'}} className='d-flex align-items-center'>
                         {/* <IconButton onClick={()=>naviagte("/admin-dashboard")}><ArrowBackRounded/></IconButton> */}
                         <h5  style={{marginLeft: '10px', height: '100%', marginTop: '5px'}} className='d-flex align-items-center'>Room Background's</h5>
                    </div>
                    <div className="d-flex align-items-center">
                         <div className='banner_create' onClick={()=> naviagte('/admin-create-room-backgrounds')}>
                              <AddRounded style={{color: '#fff'}}/>
                              <button>Create</button>
                         </div>
                         <DropdownMenu items={selectionItems} onChange={(value, i)=> {
                                   const status = selectionItemKeys[i];
                                   setStatus(status);
                                   onPageChange(null, 1, true, status);
                              }}/>
                    </div>
               </div>
               <div className="__table_root">
                    <table cellspacing="0" cellpadding="0" className="table">
                    <tr>
                         { tableHead.map((value, i)=> <th>{value}</th>) }
                    </tr>
                    { errorMessage ? null : ( data.length != 0 ? data.map((value, i)=> <Item data={value} position={i}/>) : null) }
                    </table>
                    { data.length != 0 ? null : <ErrorMessage message='Empty'/> }
                    { errorMessage ? <ErrorMessage message={errorMessage}/> : null }
                    <div className='d-flex justify-content-between'>
                    <div></div>
                         <Pagination onChange={onPageChange} count={maxLength != 0 ? ~~((maxLength/20)+1) : 0} shape="rounded" />
                    </div>
               </div>
          </>
     );
}

const Item = (props) => {
     const { _id /** token id === _id */, url, enabled, date } = props.data;
     
     const [checked, setChecked] = useState(enabled);

     const onCheckedChange = async (checked) => {
          setChecked(checked)
          try {
               const rBody = new FormData();
               rBody.append("id", _id)
               rBody.append("enabled", checked)
               const res = await axiosInstance.patch("admin/room-background", rBody, 
                    {
                         headers: { "Content-Type": "multipart/form-data" }
                    }
               )
          } 
          catch (error) {
               Toast.error("Failed to update");
               setChecked(!checked);
          }
     }

     useEffect(()=> {
          setChecked(enabled)
     }, [enabled]);

     return(
          <tr>
               <td>
                    <img className='img_banner' src={url ? url : "logo192.png"} alt="Avatar" style={{borderRadius: '0px', width: '80px', height: '130px'}}/>
               </td>
               <td><Switch onChange={(checked)=>{onCheckedChange(checked)}} checked={checked} /></td>
               <td>{date}</td>
          </tr>
     );
};

const ErrorMessage = (props) => {
     return (
          <div className='d-flex align-items-center justify-content-center' style={{height: "300px", fontSize: '16px'}}>
               {props.message}
          </div>
     );
}

export default RoomBackground;