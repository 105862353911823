import React, {useState, useEffect} from 'react'
import {useLocation} from 'react-router-dom'
import axiosInstance from './../../http/axios';

import Toast from '../../toast';
import Header from './../header/Header';

import "../../../node_modules/bootstrap/dist/css/bootstrap.min.css"

import ArrowBackRounded from '@mui/icons-material/ArrowBackRounded';
import { IconButton } from '@mui/material';
import DropdownMenu from '../dropdown_menu/DropdownMenu';
import {Pagination} from '@mui/material';
import { TextField } from '@mui/material';
import NavLink from '../nav_link/NavLink';

const RoomFullPage = () => {

     const naviagte = (a)=> {
          if(typeof a == 'string'){
               window.location.href = a;
          }
          else if(typeof a == 'number'){
               window.history.go(a);
          }
     };
     const location = useLocation();

     const id = new URLSearchParams(location.search).get("id");

     const [isSending, setIsSending] = useState(false);
     const [data, setData] = useState({
          roomId: "",
          roomDescription: "",
          banner: "",
          roomName: "",
          date: "",
          coin: 0,
          entryPassword: "",
          enabled: true,
          target: 0,
          owner: {},
          admins: [],
     });
     const [errorMessage, setErrorMessage] = useState("Loading");

     const toFirstLetterUpperCase = (str) => {
          if(str && str.length >= 2){
               return str.substring(0, 1).toUpperCase() + str.substring(1);
          }
          return str
     }

     const toggalBlock = async () => {
          try {
               const rBody = new FormData();
               rBody.append("id", id)
               const res = await axiosInstance.patch("admin/toggal-room-block", rBody, 
                    {
                         headers: { "Content-Type": "multipart/form-data" }
                    }
               );

               setData({
                    ...data,
                    enabled: !data.enabled,
               });

               Toast.success('Saved');
          } catch (error) {
               console.log(error);
               Toast.error('Failed to update');
          }
     }

     const removeBanner = async () => {
          try {
               const rBody = new FormData();
               rBody.append("id", id)
               const res = await axiosInstance.patch("admin/remove-room-banner", rBody, 
                    {
                         headers: { "Content-Type": "multipart/form-data" }
                    }
               );

               Toast.success('Banner is removed');
          } catch (error) {
               console.log(error);
               Toast.error('Failed to update');
          }
     }

     useEffect(async ()=>{
          try {
               const params = new URLSearchParams();
               params.append("id", id);

               const res = await axiosInstance.get('admin/room-full-page', {
                    params: params
               });
               setErrorMessage(null);
               setData({
                    ...res.data,
               });
          } 
          catch (error) {
               setErrorMessage("Failed");
          }
     }, []);

     if(errorMessage){
          return(
               <>
                    <Header/>
                    <div className="container d-flex justify-content-between">
                         <div style={{height: '80px'}} className='d-flex align-items-center'>
                              <IconButton onClick={()=>naviagte(-1)}><ArrowBackRounded/></IconButton>
                              <h5  style={{marginLeft: '10px', height: '100%', marginTop: '5px'}} className='d-flex align-items-center'>Room</h5>
                         </div>
                    </div>
                    <div className="container box d-flex justify-content-center align-items-center" style={{height: '90vh'}}>
                         <h1>{errorMessage}</h1>
                    </div>
               </>
          );
     }

     return (
          <>
               <Header/>
               <div className="container d-flex justify-content-between">
                    <div style={{height: '80px'}} className='d-flex align-items-center'>
                         <IconButton onClick={()=>naviagte(-1)}><ArrowBackRounded/></IconButton>
                         <h5  style={{marginLeft: '10px', height: '100%', marginTop: '5px'}} className='d-flex align-items-center'>Room</h5>
                    </div>
               </div>
               <div className="container d-flex justify-content-center" >
                    <div className="box d-flex align-items-center flex-column" style={{width: '40%'}}>
                         <div className="d-flex" style={{margin: '10px 20px'}}>
                              <img src={data.banner||"logo192.png"} style={{width: '150px', height: '150px', marginTop: '30px', marginBottom: '20px'}}/>
                         </div>

                         <div style={{width: "100%",padding: "0 6%", marginTop: '0',}}>
                              <h5>{data.roomName}</h5>
                              <p>{data.roomDescription}</p>
                         </div>

                         <p style={{ width: '100%', marginLeft: '60px', marginBottom: '20px'}}>Next Target</p>
                         <div style={{ width: '96%', margin: '0 2%'}}>
                              <h5 style={{ width: '20%', display: 'inline-block', textAlign: 'center', margin: 'none'}}>{data.coin.toLocaleString()}</h5>
                              <div style={{background: '#d8d8d8', height: '20px', width: '60%', borderRadius: '10px', display: 'inline-block', overflow: 'hidden'}}>
                                   <div style={{background: '#0471ff', height: '20px', width: (data.coin/(data.target/100) + "%"), borderRadius: '10px'}}/>
                              </div>
                              <h5 style={{ width: '20%', display: 'inline-block', textAlign: 'center', margin: 'none'}}>{data.target.toLocaleString()}</h5>
                         </div>

                         <p style={{ width: '100%', marginLeft: '60px', marginBottom: '10px', marginTop: '20px'}}>Room ID</p>
                         <h5 style={{ width: '100%', marginLeft: '60px',}}>{data.roomId}</h5>

                         <p style={{ width: '100%', marginLeft: '60px', marginBottom: '10px', marginTop: '10px'}}>Craeted At</p>
                         <h5 style={{ width: '100%', marginLeft: '60px'}}>{new Date(data.date).toLocaleString()}</h5>

                         <p style={{ width: '100%', marginLeft: '60px', marginBottom: '10px', marginTop: '10px'}}>Entry Password</p>
                         <h5 style={{ width: '100%', marginLeft: '60px'}}>{data.entryPassword||"- -"}</h5>


                         <div style={{width: '90%', marginTop: '10px', marginBottom: '40px'}}>
                              <button className={'btnx'} style={{background: data.enabled ? "red" : "green" }} onClick={toggalBlock} >{data.enabled ? "Block The Room" : "Unblock The Room"}</button>
                              <button className={'btnx'} style={{background: "red" }} onClick={()=> {setData({...data, banner: null}); removeBanner()}}>Remove room banner</button>
                              <button className='btnx' onClick={()=>naviagte(`/admin-room-medals?id=${id}`)}>View Medal's</button>
                         </div>
                    </div>
                    <div className="box d-flex justify-content-around row" style={{width: '35%', marginLeft: '20px'}}>
                         <div>
                              <p style={{ width: '100%', marginLeft: '10px', marginTop: '20px'}}>Room Owner</p>
                              <UserItem data={data.owner} />

                              <p style={{ width: '100%', marginLeft: '10px', marginTop: '20px'}}>Room Admin's</p>
                              {
                                   data.admins.length != 0 ? data.admins.map((val)=> <UserItem data={val} />) : <p style={{textAlign: 'center', marginTop: '50px'}}>No admin</p>
                              }
                         </div>

                    </div>
               </div>
          </>
     );
}


const UserItem = ({data}) => {

     const naviagte = (a)=> {
          if(typeof a == 'string'){
               window.location.href = a;
          }
          else if(typeof a == 'number'){
               window.history.go(a);
          }
     };

     return(
          <div style={{height: '80px', margin: '10px 10px', background: '#eeeeee60', borderRadius: '10px', padding: '0 20px', }} className='d-flex justify-content-between align-items-center'>
               <div className="d-flex">
                    <div style={{width: '50px', height: '50px', borderRadius: '25px', overflow: 'hidden', display: 'inline-block', marginTop: '15px'}}>
                         <img src={data.profilePhoto||"/assets/images/faces/user.png"} style={{width: '50px', height: '50px', borderRadius: '50%'}}/>
                    </div>
                    <div style={{display: 'flex', justifyContent: 'center', flexDirection: 'column',height: '80px', marginLeft: '20px'}}>
                         <span style={{fontWeight: 'bold', color: 'black', fontSize: '22px'}}>{data.userName}</span>
                         <span style={{ fontSize: '12px'}}>User ID: {data.userId}</span>
                    </div>
               </div>
               <button onClick={()=>naviagte(`/admin-user-profile?id=${data._id}&userName=${data.userName}`)} style={{ height: '30px', width: '70px' ,background: "green", border: "none", color: "white", borderRadius: "5px"}} >Profile</button>
          </div>
     );
}


const ErrorMessage = (props) => {
     return (
          <div className='d-flex align-items-center justify-content-center' style={{height: "300px", fontSize: '16px'}}>
               {props.message}
          </div>
     );
}

export default RoomFullPage;