import React, { useState, useEffect } from 'react'
import axiosInstance from './../../http/axios';

import Toast from '../../toast';

import { TextField, Button, Fab, IconButton } from '@mui/material';
import AddRounded from '@mui/icons-material/AddRounded';
import ArrowBackRounded from '@mui/icons-material/ArrowBackRounded';

import Header from './../header/Header';

import DropdownMenu from '../dropdown_menu/DropdownMenu';

import { Svga } from 'react-svga'


const EditGift = ()=>{

     const giftId = new URLSearchParams(window.location.search).get("id");

     const [isSending, setIsSending] = useState(false);
     const [data, setData] = useState({
          category: '',
          name: '',
          price: 1,
          file: '',
     })
     const selectionItems = ["None"];


     const a = {
          hii: 'ok'
     };

     const navigate = (a)=> {
          if(typeof a == 'string'){
               window.location.href = a;
          }
          else if(typeof a == 'number'){
               window.history.go(a);
          }
     };

     const onChange = (event)=>{
          const { value, name } = event.target;
          setData(pVal => {
               if(name != 'file'){
                    return {
                         ...pVal,
                         [name]: value
                    };
               }
               else{
                    return {
                         ...pVal,
                         file: event.target.files[0]
                    };
               }
          });
          console.log(data);
     }

     const validate = () => {
          if(!data.category){
               Toast.error("Please enter gift category");
               return false;
          }
          else if(!data.name){
               Toast.error("Please enter gift name");
               return false;
          }
          else if(!data.price){
               Toast.error("Please enter gift value (coin)");
               return false;
          }
          if(!data.file){
               Toast.error("Please upload banner image");
               return false;
          }
          return true;
     }

     useEffect(async ()=>{
          try {
               const params = new URLSearchParams();
               params.append("id", giftId);

               const res = await axiosInstance.get('admin/gift-for-edit', {
                    params: params
               });
               setData({
                    ...res.data,
               });
          } 
          catch (error) {
               
          }
     }, []);

     function srcToFile(src, fileName, mimeType){
          return (fetch(src)
              .then(function(res){return res.arrayBuffer();})
              .then(function(buf){return new File([buf], fileName, {type:mimeType});})
          );
     }


     const onSubmit = async ()=>{

          if(!validate()) return;

          setIsSending(true)

          var giftImage = null;

          const canvas = document.getElementsByTagName("canvas")[0];

          if(canvas){
               const data = canvas.toDataURL('image/png');
               try{
                    giftImage = await srcToFile(data, "test.png", 'image/png');
               }
               catch(er){

               }
          }

          try {
               const rBody = new FormData();
               rBody.append("id", giftId);
               Object.keys(data).forEach(key => rBody.append(key, data[key]));
               rBody.append("giftImage", giftImage);
               const res = await axiosInstance.patch("admin/gift-edit", rBody, 
                    {
                         headers: { "Content-Type": "multipart/form-data" }
                    }
               )
               Toast.success('Success');
               setIsSending(false);
               navigate('/admin-gifts')
          } catch (error) {
               console.log(error);
               setIsSending(false)
               Toast.error('Failed');
          }
     }

     return(
          <>
               <Header/>
               <div className="d-flex justify-content-between">
                    <div style={{height: '80px'}} className='d-flex align-items-center'>
                         <IconButton onClick={()=>navigate(-1)}><ArrowBackRounded/></IconButton>
                         <h5  style={{marginLeft: '10px', height: '100%', marginTop: '5px'}} className='d-flex align-items-center'>Edit gift</h5>
                    </div>
               </div>
               <div className="d-flex justify-content-center" style={{height:'auto', marginTop: '50px'}}>
                    <div className='notification_container d-flex align-=items-center flex-column'>
                         {/* <h2 className='text-center' style={{marginTop: '50px'}}>Banner</h2> */}
                         <TextField name='category' value={data.category} onChange={onChange} style={{width: '90%', marginTop: '40px', color: 'red'}} fullWidth label="Category" type="text" />
                         <TextField name='name' value={data.name} onChange={onChange} style={{width: '90%', marginTop: '20px', color: 'red'}} fullWidth label="Name" type="text" />
                         <TextField name='price' value={data.price} onChange={onChange} style={{width: '90%', marginTop: '20px', color: 'red'}} fullWidth label="Coin" type="number" />
                         
                         <div style={{ width: '100%', marginLeft: '35px'}}>
                              <div className='notification-import-image-root d-flex justify-content-center align-items-center' style={{marginTop: '20px', background: '#222323'}}>
                                   { data.file ? <Svga src={typeof data.file == "string" ? data.file : URL.createObjectURL(data.file)} option={{ loop: true }} className='h-100'/> : <div> <AddRounded /> Gift </div> }
                              </div>
                              <label htmlFor="upload-photo" style={{marginTop: '10px'}}>
                                   <input name="file" onChange={onChange} style={{ display: 'none' }} id="upload-photo" type="file" itemType='image/*' accept='.svga' />
                                   <Fab color="primary" size="small" component="span" aria-label="add" variant="extended">
                                        <AddRounded /> Change
                                   </Fab>
                              </label>
                         </div>
                         <button onClick={onSubmit} style={{width: '90%', marginTop: '30px', marginBottom: "30px", cursor: isSending ? "progress" : 'pointer'}} className="__btn">Save</button>
                    </div>
               </div>
          </>
     );
}

export default EditGift;