import React, {useState, useEffect} from 'react'
import {NavLink, useNavigate, useLocation} from 'react-router-dom'
import axiosInstance from './../../http/axios';

import Toast from '../../toast';
import Header from './../header/Header';

import "../../../node_modules/bootstrap/dist/css/bootstrap.min.css"

import ArrowBackRounded from '@mui/icons-material/ArrowBackRounded';
import ArrowForwardRounded from '@mui/icons-material/ArrowForwardRounded';
import AddRounded from '@mui/icons-material/AddRounded';
import { TextField, Button, Fab, IconButton } from '@mui/material';
import DropdownMenu from '../dropdown_menu/DropdownMenu';
import {Pagination} from '@mui/material';

import './Chat.css';

const HelpAndSupportChat = () => {

     const location = useLocation();
     const naviagte = (a)=> {
          if(typeof a == 'string'){
               window.location.href = a;
          }
          else if(typeof a == 'number'){
               window.history.go(a);
          }
     };
     const searchPrams = new URLSearchParams(location.search);
     const tokenId = searchPrams.get("tokenId");
     const subject = searchPrams.get("subject");
     const lastContent = searchPrams.get("lastContent");
     const lastContentType = searchPrams.get("lastContentType");
     const fileUrl = searchPrams.get("fileUrl");
     const fileType = searchPrams.get("fileType");
     const category = searchPrams.get("category");
     const contactInfo = searchPrams.get("contactInfo");

     const [data, setData] = useState([]);
     const [maxLength, setMaxDataLength] = useState(0);
     const [message, setMessage] = useState("");
     const [errorMessage, setErrorMessage] = useState(null);
     const [isSending, setIsSending] = useState(null);
     const tableHead = ["Category", "Subject", "Last message from", "Last message", "Date", ""];
     const selectionItems = ["New data", "Old data"];
     const selectionItemKeys = ["new", "old"];

     const findChats = async () => {
          try {
               const params = new URLSearchParams();
               params.append("skip", 0);
               params.append("limit", 100);
               params.append("feedbackTokenId", tokenId);

               const res = await axiosInstance.get('admin/feedback-content', {
                    params: params
               });
               console.log(res.data);
               for(let chat of res.data){
                    console.log(chat);  
                    if(chat.contentType == "text"){
                         addMessage(`${chat.content}` , chat.sender !== "admin");
                    }
                    if(chat.contentType == "text_with_image" || chat.contentType == "text_with_video"){
                         addMessage(`${chat.content}`, chat.sender !== "admin");
                         addMessage(`${chat.fileUrl}`, chat.sender !== "admin");
                    }
               }
          } 
          catch (error) {
               setErrorMessage("Failed");
               console.log(error);
          }
     }

     const uploadMessage = async fileOrString => {
          if(fileOrString instanceof File){
               setIsSending(true);
          }
          else {
               addMessage(fileOrString)
          }
          try {
               const body = new FormData();
               body.append("feedbackId", tokenId);
               if(fileOrString instanceof File){
                    body.append("file", fileOrString);
                    body.append("contentType", fileOrString.type.indexOf("image") != -1 ? "text_with_image" : "text_with_video");
                    body.append("content", fileOrString.type.indexOf("image") != -1 ? "Image" : "Video");
               }
               else {
                    body.append("content", fileOrString);
                    body.append("contentType", "text");
               }

               const res = await axiosInstance.post('admin/feedback-content', body, {
                    headers: { "Content-Type": "multipart/form-data" }
               });
               setIsSending(false);
               if(fileOrString instanceof File){
                    addMessage(res.data.content)
               }
          } 
          catch (error) {
               setErrorMessage("Failed");
               console.log(error);
               setIsSending(false);
          }
     }

     function addMessage(message, itsMe) {
          var messageList = document.getElementById("message-list");
          
          var scrollToBottom = (messageList.scrollHeight - messageList.scrollTop - messageList.clientHeight < 80);
        
          var lastMessage = messageList.children[messageList.children.length-1];
          
          var newMessage = document.createElement("span");
          if(message.startsWith('http://') || message.startsWith('https://')){
               newMessage.innerHTML = `<a href="${message}" target="_blank" rel="noopener noreferrer">${message}</a>`;
          }
          else {
               newMessage.innerText = message;
          }
        
          var className;
          
          if(itsMe)
          {
            className = "me";
            scrollToBottom = true;
          }
          else
          {
            className = "not-me";
          }
          
          if(lastMessage && lastMessage.classList.contains(className))
          {
            lastMessage.appendChild(document.createElement("br"));
            lastMessage.appendChild(newMessage);
          }
          else
          {
            var messageBlock = document.createElement("div");
            messageBlock.classList.add(className);
            messageBlock.appendChild(newMessage);
            messageList.appendChild(messageBlock);
          }
          
          if(scrollToBottom) messageList.scrollTop = messageList.scrollHeight;
     }

     const onMessageSend = event =>{
          if(message.trim() !== ""){
               uploadMessage(message.trim(), true);
               setMessage("");
          }
     }

     const chooseFile = e => {
          var file = document.getElementById("file");

          file.onchange = function(e){
               if (e.target.files && e.target.files[0]) {
                    uploadMessage(e.target.files[0]);
               }
          };

          file.click();
     }

     useEffect(async ()=>{
          findChats();
     }, []);

     return (
          <>
               <Header/>
               <div className="container d-flex justify-content-between">
                    <div className='subject-box' style={{width: '40%'}}>
                         <header className='m-header'>
                              <IconButton onClick={()=>naviagte(-1)} style={{marginRight: '10px'}}><ArrowBackRounded/></IconButton>
                              <h1>{category}</h1>
                              <span style={{flex: 1}}></span>
                         </header>
                         <div style={{display: 'flex', margin: '10px 30px'}}>
                              <h5 style={{display: 'inline'}}>Category</h5>
                              <span style={{flex: 1}}></span>
                              {category||"None"}
                         </div>
                         <div style={{margin: '10px 30px'}}>
                              <h5>Contact info</h5>
                              {contactInfo != 'undefined' ? contactInfo: "None"}
                         </div>
                         <div style={{margin: '10px 30px'}}>
                              <h5>Subject</h5>
                              {subject}
                         </div>
                         <div style={{margin: '10px 30px'}}>
                              <h5>File {`(${fileType != 'undefined' ? fileType: "None"})`}</h5>
                             { fileUrl  != 'undefined' ? <a href={fileUrl} target="_blank" rel="noopener noreferrer">{fileUrl}</a>: "None" }
                         </div>
                    </div>
                    <div className="chat-box"  style={{width: '58%'}}>
                         <header className='m-header'>
                              <h1 style={{marginLeft: '10px'}}>Chat</h1>
                              <span style={{flex: 1}}></span>
                         </header>
                         <section id="message-list"></section>
                         <footer>
                              <textarea onChange={(event)=>setMessage(event.target.value)} value={message} id="message-input" type="text" placeholder="Type a message..."/>
                              <div className="d-flex justify-content-between flex-column">
                                   <button className='chat-actions' onClick={onMessageSend}>Send</button>
                                   <button className='chat-actions' onClick={chooseFile} style={{marginTop: '10px', cursor: isSending ? 'wait' : 'pointer'}}>
                                        <input id='file' name="file" style={{display: 'none'}} type="file" multiple accept="image/*,video/*"/>
                                        Upload file
                                   </button>
                              </div>
                         </footer>
                    </div>
               </div>
          </>
     );
}

export default HelpAndSupportChat;