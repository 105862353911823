import React, {useState} from 'react'
import axiosInstance from './../../http/axios';

import Toast from '../../toast';

import { TextField, Button, Fab, IconButton } from '@mui/material';
import AddRounded from '@mui/icons-material/AddRounded';
import ArrowBackRounded from '@mui/icons-material/ArrowBackRounded';

import Header from './../header/Header';

import DropdownMenu from '../dropdown_menu/DropdownMenu';
import { useLocation } from 'react-router-dom';
import { Svga } from 'react-svga';

const CreateStoreItem = ()=>{

     // const location = useLocation();

     // const type = new URLSearchParams(location.search).get("type");

     
     const selectionItems =  ["Headwear", "Mounts", "VIP Headwear", "VIP Mounts", "Room Reward Headwear", "Couple Event Headwear", "Couple Event Mounts", "Event Headwear", "Event Mounts"];
     const selectionItemKeys = ["headwear", "mounts", "vip_headwear", "vip_mounts", "room_reward_headwear", "couple_event_headwear", "couple_event_mounts","event_headwear", "event_mounts"];

     const [isSending, setIsSending] = useState(false);
     const [data, setData] = useState({
          value: 1,
          validDays: 1,
          file: '',
          type: selectionItemKeys[0]
     })

     const navigate = (a)=> {
          if(typeof a == 'string'){
               window.location.href = a;
          }
          else if(typeof a == 'number'){
               window.history.go(a);
          }
     };

     const onChange = (event)=>{
          const { value, name } = event.target;
          setData(pVal => {
               if(name != 'file'){
                    return {
                         ...pVal,
                         [name]: value
                    };
               }
               else{
                    return {
                         ...pVal,
                         file: event.target.files[0]
                    };
               }
          });
          console.log(data);
     }

     const validate = () => {
          if(!data.file){
               Toast.error("Please upload banner image");
               return false;
          }
          else if(!`${data.text}`.trim()){
               Toast.error("Please enter text");
               return false;
          }
          else if(!`${data.type}`.trim()){
               Toast.error("Failed (Go back)");
               return false;
          }
          return true;
     }

     const onSubmit = async ()=>{

          if(!validate()) return;

          setIsSending(true)
          try {
               const rBody = new FormData();
               // rBody.append("type", type)
               Object.keys(data).forEach(key => rBody.append(key, data[key]));
               const res = await axiosInstance.post("admin/store-item", rBody, 
                    {
                         headers: { "Content-Type": "multipart/form-data" }
                    }
               )
               Toast.success('Success');
               setIsSending(false);
               navigate(-1)
          } catch (error) {
               console.log(error);
               setIsSending(false)
               Toast.error('Failed');
          }
     }

     return(
          <>
               <Header/>
               <div className="d-flex justify-content-between">
                    <div style={{height: '80px'}} className='d-flex align-items-center'>
                         <IconButton onClick={()=>navigate(-1)}><ArrowBackRounded/></IconButton>
                         <h5  style={{marginLeft: '10px', height: '100%', marginTop: '5px'}} className='d-flex align-items-center'>Create Store Item</h5>
                    </div>
               </div>
               <div className="d-flex justify-content-center" style={{height:'auto', marginTop: '50px'}}>
                    <div className='notification_container d-flex align-items-center flex-column'>
                         <h2 className='text-center' style={{marginTop: '50px'}}>Store Item</h2>
                         <TextField name='value' value={data.value} onChange={onChange} style={{width: '90%', marginTop: '40px', color: 'red'}} fullWidth label="Value" type="number" InputProps={{ inputProps: { min: 0, max: 999999 } }} />
                         <TextField name='validDays' value={data.validDays} onChange={onChange} style={{width: '90%', marginTop: '40px', color: 'red'}} fullWidth label="Valid Days" type="number" InputProps={{ inputProps: { min: 0, max: 999999 } }} />
                         <div className="w-100" style={{padding: '20px 0 10px 20px'}}>
                              <span>Type</span>
                         </div>
                         <DropdownMenu width="300px" className="banner-rediract-activity-root" items={selectionItems} onChange={(value, i)=> {
                                   setData({
                                        ...data,
                                        type: selectionItemKeys[i]
                                   });
                              }}/>
                         <div style={{ width: '100%', marginLeft: '35px'}}>
                              <div className='notification-import-image-root d-flex justify-content-center align-items-center' style={{marginTop: '20px'}}>
                                   { data.file ? <Svga src={URL.createObjectURL(data.file)} option={{ loop: true }} className='h-100'/> : <div> <AddRounded /> .svga </div> }
                              </div>
                              <label htmlFor="upload-photo" style={{marginTop: '10px'}}>
                                   {/* <input name="file" onChange={onChange} style={{ display: 'none' }} id="upload-photo" type="file" itemType='image/*' /> */}
                                   <input name="file" onChange={onChange} style={{ display: 'none' }} id="upload-photo" type="file" accept='.svga' />
                                   <Fab color="primary" size="small" component="span" aria-label="add" variant="extended">
                                        <AddRounded /> Upload
                                   </Fab>
                              </label>
                         </div>
                         <button onClick={onSubmit} style={{width: '90%', marginTop: '30px', marginBottom:"30px", cursor: isSending ? "progress" : 'pointer'}} className="__btn">Create</button>
                    </div>
               </div>
          </>
     );
}

export default CreateStoreItem;